<template>
  <div class="myfilters poppins" style="z-index: 10 !important">
    <div v-if="filter['type'] === 'report_date_month_range'">
      <v-select
        class="px-2"
        dense
        v-model="queryObject[`${filter.name}.select`]"
        :items="dateTypes"
        item-text="text"
        item-value="value"
        @change="handleChange"
        :label="filter.title.en ? filter.title.en : filter.title"
        prepend-inner-icon="mdi-calendar-range"
      >
        <template v-slot:item="{ item }"
          ><div
            class="py-4"
            style="height: 100%; width: 100%; display: block"
            @click="updateDateOfCharts(item.value)"
          >
            {{ item.text }}
          </div>
        </template>
      </v-select>
      <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
        <v-date-picker
          v-model="queryObject[`${filter.name}.date`]"
          range
          type="month"
          scrollable
          @change="handleChange"
          :max="new Date().toISOString().split('T')[0]"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              () => {
                dialog = false;
                dateType = 'this_month';
              }
            "
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="
              () => {
                if (queryObject[`${filter.name}.date`].length > 1) {
                  updateDateOfCharts('custom');
                }
              }
            "
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <div v-else-if="filter['type'] === 'select2Single'">
      <v-autocomplete
        @change="handleChange"
        class="mx-1"
        :items="filter['values']"
        v-model="queryObject[`${filter.name}`]"
        :label="filter.title"
        item-text="text"
        item-value="index"
        dense
        clearable
      ></v-autocomplete>
    </div>
    <div v-else-if="filter['type'] === 'range'">
      <v-range-slider
        @change="handleChange"
        class="mx-1"
        v-model="queryObject[`${filter.name}`]"
        :label="filter.title"
        ticks
        tick-size="1"
        thumb-label
        min="0"
        max="100"
        dense
        clearable
      ></v-range-slider>
    </div>
    <div v-else-if="filter['type'] === 'phone'">
      <VueTelInputVuetify
        class="ma-0 pa-0 mx-1"
        v-model="queryObject[`${filter.name}`]"
        :inputOptions="{ showDialCode: true, tabindex: 0 }"
        mode="international"
        @change="handleChange"
        dense
        disabledFetchingCountry
        defaultCountry="AE"
      ></VueTelInputVuetify>
    </div>
    <div v-else-if="filter['type'] === 'multiImageSelect'">
      <v-autocomplete
        @change="handleChange"
        class="mx-1"
        multiple
        :items="filter['values']"
        v-model="queryObject[`${filter.name}`]"
        :label="filter.title"
        item-text="text"
        item-value="index"
        dense
        messages=""
        clearable
        small-chips
        deletable-chips
      >
        <template v-slot:item="{ item }">
          <show-url-image :url="item.image" class="mr-3"> </show-url-image>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="filter['type'] === 'multipleSelect'">
      <v-autocomplete
        @change="handleChange"
        class="mx-1"
        multiple
        :items="filter.values"
        v-model="queryObject[filter.name]"
        :label="filter.title"
        item-text="text"
        item-value="index"
        dense
        messages=""
        clearable
        small-chips
        deletable-chips
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="font-size-sm">
                Select All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="filter['type'] === 'date'">
      <v-menu
        v-model="menu2"
        class=""
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        dense
      >
        <v-date-picker
          v-model="queryObject[`${filter.name}`]"
          @change="handleChange"
          range
          clearable
          :max="new Date().toISOString().split('T')[0]"
        ></v-date-picker>

        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            @change="handleChange"
            v-model="queryObject[`${filter.name}`]"
            :label="filter.title"
            prepend-icon="mdi-calendar"
            clearable
            dense
            class="mx-1"
            elevation-0
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
      </v-menu>
    </div>
    <div v-else-if="filter['type'] === 'select2DateRange'">
      <v-dialog
        ref="dialog"
        v-model="menu2"
        :return-value.sync="queryObject[`${filter.name}`]"
        persistent
        width="360px"
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="queryObject[`${filter.name}.date`]"
            label="Date range"
            prepend-icon="mdi-calendar"
            class="mx-1"
            @change="handleChange"
            clearable
            dense
            elevation-0
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          @change="handleChange"
          v-model="queryObject[`${filter.name}.date`]"
          :max="new Date().toISOString().split('T')[0]"
          range
          clearable
        >
          <div class="w-100 d-block">
            <v-select
              @change="handleChange"
              item-text="text"
              v-model="queryObject[`${filter.name}.select`]"
              item-value="index"
              :items="filter.values"
              label="Filter by"
              dense
            ></v-select>
            <div class="row py-2 mt-2">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    handleChange();
                    menu2 = false;
                  }
                "
              >
                OK
              </v-btn>
            </div>
          </div>
        </v-date-picker>
      </v-dialog>
    </div>
    <div v-else-if="filter['type'] === 'inputText'">
      <v-text-field
        class="mx-1"
        v-model="queryObject[`${filter.name}`]"
        @keydown="isTextInputEnterHited"
        @change="handleChange"
        :label="filter['title']"
        required
        clearable
        dense
      ></v-text-field>
    </div>
    <div v-else-if="filter['type'] === 'inputTextMultiple'" class="scrollable">
      <v-combobox
        class="multi-select mx-1"
        dense
        v-model="queryObject[`${filter.name}`]"
        :hide-no-data="!queryObject[`${filter.name}`]"
        :label="filter.title"
        @change="
          handleChangeMultiple(queryObject[`${filter.name}`], filter.name)
        "
        multiple
        deletable-chips
        clearable
        allow-overflow
        small-chips
      >
      </v-combobox>
    </div>
    <div v-if="filter['type'] === 'numberRangeSelector'" class="">
      <v-menu
        v-model="menu3"
        class=""
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        rounded
        min-width="300"
        width="340"
      >
        <v-card>
          <v-card-title class="v-label theme--light">{{
            filter.title
          }}</v-card-title>
          <v-card-text>
            <div class="row ma-0 pa-0">
              <div class="col-6 pa-0 pr-1">
                <v-text-field
                  v-model="queryObject[`${filter.name}`][0]"
                  type="Number"
                  :min="0"
                  @change="handleChange"
                  outlined
                  dense
                  label="Min"
                  hide-details
                ></v-text-field>
              </div>
              <div class="col-6 pa-0 pl-1">
                <v-text-field
                  v-model="queryObject[`${filter.name}`][1]"
                  type="Number"
                  :min="0"
                  @change="handleChange"
                  outlined
                  dense
                  label="Max"
                  hide-details
                ></v-text-field>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            @change="handleChange"
            v-model="queryObject[`${filter.name}`]"
            :label="filter.title"
            dense
            readonly
            class="mx-1"
            elevation-0
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
      </v-menu>
    </div>
    <div v-if="filter['type'] === 'percentageRangeSelector'" class="">
      <v-menu
        v-model="menu4"
        class=""
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        rounded
        min-width="300"
      >
        <v-card>
          <v-card-title class="v-label theme--light">{{
            filter.title
          }}</v-card-title>
          <v-card-text>
            <div class="row ma-0 pa-0">
              <div class="col-6 pa-0 pr-1">
                <v-text-field
                  v-model="queryObject[`${filter.name}`][0]"
                  type="Number"
                  :min="0"
                  :max="100"
                  @change="handleChange"
                  outlined
                  dense
                  label="Min"
                  hide-details
                  suffix="%"
                ></v-text-field>
              </div>
              <div class="col-6 pa-0 pl-1">
                <v-text-field
                  v-model="queryObject[`${filter.name}`][1]"
                  type="Number"
                  :min="0"
                  :max="100"
                  @change="handleChange"
                  outlined
                  dense
                  label="Max"
                  hide-details
                  suffix="%"
                ></v-text-field>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            @change="handleChange"
            v-model="queryObject[`${filter.name}`]"
            :label="filter.title"
            dense
            readonly
            class="mx-1"
            elevation-0
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
      </v-menu>
    </div>
  </div>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import ShowUrlImage from "@/own/components/datatable/ShowUrlImage.vue";

export default {
  name: "MyFilter",
  components: { VueTelInputVuetify, ShowUrlImage },
  props: {
    filter: {
      required: true,
    },
    submitFilterValues: {
      required: true,
    },
    defaultValues: {
      required: false,
      default: false,
    },
  },
  data: () => ({
    queryObject: {},
    menu2: false,
    dialog: false,
    menu3: false,
    menu4: false,
    dateTypes: [
      { text: "This year", value: "this_year" },
      { text: "All time", value: "all_time" },
      { text: "Custom", value: "custom" },
    ],
  }),
  beforeMount() {
    this.$set(this.queryObject, this.filter.name, []);
  },
  mounted() {
    this.initial();
  },
  computed: {
    selectedAll() {
      if (
        this.filter.type === "multipleSelect" &&
        Array.isArray(this.queryObject[this.filter.name])
      ) {
        return (
          this.queryObject[this.filter.name].length ===
          this.filter.values.length
        );
      } else return false;
    },
    selectedSome() {
      if (
        this.filter.type === "multipleSelect" &&
        Array.isArray(this.queryObject[this.filter.name])
      ) {
        return this.queryObject[this.filter.name].length > 0;
      } else return false;
    },
    icon() {
      if (this.selectedAll) return "mdi-checkbox-marked";
      if (this.selectedSome) return "mdi-minus-box-outline";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    initial() {
      if (this.filter.type === "select2DateRange") {
        this.queryObject[`${this.filter.name}.select`] =
          this.filter.values[0].index;
      }
      if (this.filter.type === "range") {
        this.queryObject[`${this.filter.name}`] = [
          this.filter.min,
          this.filter.max,
        ];
      }
      if (
        this.filter.type === "numberRangeSelector" ||
        this.filter.type === "percentageRangeSelector"
      ) {
        this.queryObject[`${this.filter.name}`] = [];
      }
      if (this.defaultValues) {
        this.queryObject = this.defaultValues;
      }
    },
    toggle() {
      if (this.selectedAll) {
        this.$set(this.queryObject, this.filter.name, []);
      } else {
        let arr = this.filter.values.map((value) => value.index);
        this.$set(this.queryObject, this.filter.name, arr);
      }
      this.handleChange();
    },
    clearData: function () {
      if (this.filter.type === "multipleSelect")
        this.$set(this.queryObject, this.filter.name, []);
      else this.$set(this.queryObject, this.filter.name, "");
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false;
      this.initial();
    },
    handleChange() {
      this.$emit("option", this.queryObject);
    },
    handleChangeMultiple(e, filter) {
      this.queryObject[filter] = this.seperateMultipleInput(e);
      this.$emit("option", this.queryObject);
    },
    isTextInputEnterHited(e) {
      if (e.keyCode === 13) {
        this.submitFilterValues();
      }
    },
    seperateMultipleInput(val) {
      let seperated = [];
      val.forEach((element) => {
        let subelement = element.trim().split(/(?:,| )+/);
        subelement.forEach((sub) => {
          seperated.push(sub);
        });
      });
      return seperated;
    },
    updateDateOfCharts(val) {
      if (val === "custom") {
        if (!this.dialog) {
          this.dialog = !this.dialog;
        } else {
          this.dialog = !this.dialog;
        }
      } else {
        if (this.queryObject[`${this.filter.name}.date`])
          this.queryObject[`${this.filter.name}.date`] = null;
        this.$nextTick(() => {
          this.handleChange();
        });
      }
    },
  },
  watch: {
    defaultValues(val) {
      this.queryObject = val;
    },
  },
};
</script>

<style>
.myfilters .v-select__selections {
  height: 26px;
  overflow-y: scroll;
}
.myfilters .v-select__selections::-webkit-scrollbar {
  display: none;
}
</style>
