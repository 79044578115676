<template>
  <date-range-picker
    class="w-100 w-md-auto"
    ref="picker"
    :opens="position"
    :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
    :close-on-esc="false"
    :showDropdowns="true"
    :ranges="ranges"
    min-date="01-01-2018"
    :max-date="theme === 'Classic' ? new Date().toLocaleDateString() : null"
    control-container-class="form-control max-h-45px"
    v-model="dateRange"
    @update="updateDateValue"
  >
    <!--            @select="selectDate"-->
    <template v-slot:input="picker">
      <div class="h-100 d-flex align-center">
        <span class="svg-icon svg-icon-2 mr-2">
          <v-icon size="22">mdi-calendar</v-icon>
        </span>

        <input
          type="text"
          class="custom-input"
          placeholder="Select a date"
          :value="picker.rangeText === ' - ' ? '' : picker.rangeText"
        />
      </div>
    </template>

    <template v-slot:footer="picker">
      <div
        :class="[
          { 'drp-buttons py-4': theme === 'Classic' },
          { 'py-3': theme === 'Basic' },
          'px-6 poppins d-flex justify-content-between',
        ]"
      >
        <div>
          <v-switch
            v-model="theme"
            dense
            flat
            inset
            @change="setThemeType"
            true-value="Classic"
            false-value="Basic"
            class="mt-0"
          >
            <template v-slot:label
              ><span class="font-size-lg font-weight-bolder">{{
                theme
              }}</span></template
            >
          </v-switch>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-light ls1 py-2 px-7 mr-3"
            @click="picker.clickCancel"
          >
            Cancel</button
          ><button
            @click="picker.clickApply"
            type="button"
            class="btn btn--print-upload ls1 py-2 px-4"
          >
            Apply
          </button>
        </div>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "DateTimePicker",
  components: { DateRangePicker },
  props: {
    position: { type: String, default: "center" },
  },
  data() {
    return {
      dateRange: {
        startDate: this.today(),
        endDate: this.today(),
      },
      theme: "",
    };
  },
  methods: {
    dataOfFilters() {
      let startDate, endDate;
      const pattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;

      if (pattern.test(this.dateRange.startDate)) {
        startDate = this.slashSeperatedToISO(this.dateRange.startDate);
        endDate = this.slashSeperatedToISO(this.dateRange.endDate);
      } else {
        startDate = this.extendedToISO(this.dateRange.startDate);
        endDate = this.extendedToISO(this.dateRange.endDate);
      }
      const filterData = {
        date_range_type: "custom",
        date_range: [startDate, endDate],
        ...this.filterData,
      };
      return filterData;
    },
    slashSeperatedToISO(date) {
      const [month, day, year] = date.split("/");
      const js_date = new Date(year, month - 1, day);
      return this.dateFormatter(js_date);
    },
    extendedToISO(val) {
      const date = new Date(val);
      return this.dateFormatter(date);
    },
    today() {
      const d = new Date();
      return this.dateFormatter(d);
    },
    updateDateValue() {
      let startDate, endDate;
      const pattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;

      if (pattern.test(this.dateRange.startDate)) {
        startDate = this.slashSeperatedToISO(this.dateRange.startDate);
        endDate = this.slashSeperatedToISO(this.dateRange.endDate);
      } else {
        startDate = this.extendedToISO(this.dateRange.startDate);
        endDate = this.extendedToISO(this.dateRange.endDate);
      }

      this.$emit("on-submit", [startDate, endDate]);
    },
    dateFormatter(date, charForJoin = "-") {
      return [
        date.getFullYear(),
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`,
        date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
      ].join(charForJoin);
    },
    getThemeType() {
      let theme = localStorage.getItem("date-picker-style");
      if (!theme) {
        this.theme = "Basic";
      } else this.theme = theme;
    },
    setThemeType() {
      if (this.theme) {
        localStorage.setItem("date-picker-style", this.theme);
      }
    },
  },
  computed: {
    ranges: function () {
      if (this.theme === "Basic") return false;

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      return {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "Last 30 Days": [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "This month": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "Last month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
        "Last year": [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear() - 1, 12, 31),
        ],
      };
    },
  },
  mounted() {
    this.updateDateValue();
    this.getThemeType();
  },
  watch: {
    theme(val) {
      const color = val === "Classic" ? "#fbfaf8" : "white";
      document.documentElement.style.setProperty(
        "--dynamic-datepicker-bg",
        color
      );
    },
  },
};
</script>

<style lang="scss">
.table-condensed {
  // .off {
  //   display: none;
  // }
  .off.in-range {
    background-color: #ebf4f8 !important;
  }
  .end-date {
    background-color: rgba(183, 4, 126, 0.8) !important;
  }
}
.weekend.active.in-range.end-date {
  background-color: rgba(183, 4, 126, 0.8) !important;
}
.date-range-selector {
  margin-top: 10px !important;
  .v-input__control {
    .v-input__slot {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.vue-daterange-picker {
  .form-control {
    border: none;
  }
}
.show-calendar {
  background-color: var(--dynamic-datepicker-bg, "white");
}
</style>
