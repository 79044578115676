<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    fullscreen
    class=""
  >
    <div class="bg-white poppins position-relative min-h-100">
      <div
        class="modal-header py-3 align-center bg-white position-sticky top-0 left-0 zindex-5"
      >
        <h4 class="mb-0 font-weight-bolder poppins">Record Details</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <div v-for="(item, i) in other" :key="i">
          <pop-up-selector :item="item"></pop-up-selector>
        </div>

        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import PopUpSelector from "@/own/components/datatable/popup/PopUpSelector.vue";
import ApiService from "@/core/services/api.service";
export default {
  name: "ShowDetails",
  components: { PopUpSelector },
  props: ["showUrl"],
  data: () => ({
    id: null,
    dialog: false,
    serverData: null,
    formData: {
      comment: null,
    },
  }),
  computed: {
    other() {
      return this.serverData;
    },
  },
  methods: {
    setId(id) {
      this.id = id;
    },
    loadDetailData() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: this.id };
      ApiService.post(this.showUrl, data)
        .then((response) => {
          // console.log(response);
          this.$store.commit(SET_PAGE_LOADING, false);
          this.serverData = response.data.detail;
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.id = null;
        this.dialog = !this.dialog;
      } else {
        this.dialog = !this.dialog;
        this.loadDetailData();
      }
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
</style>
