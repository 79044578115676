<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit ASN</h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <v-stepper
            v-model="tab"
            non-linear
            outlined
            rounded="4"
            flat
            v-if="isStepperReady"
            @change="updateValidationStateOfSteps"
          >
            <v-stepper-header class="step-head-main">
              <v-stepper-step
                v-for="(item, i) in categories"
                :key="i"
                class="step-head"
                complete
                editable
                complete-icon="mdi-check-circle"
                edit-icon="mdi-check"
                error-icon="mdi-alert-circle"
                :step="i"
                :rules="[
                  () => {
                    return item.valid;
                  },
                ]"
              >
                {{ item.name }}
                <small v-if="!item.valid">Invalid</small>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items class="">
              <v-stepper-content
                step="0"
                key="Pre-Alert Information"
                class="pt-2"
              >
                <div class="row pt-4">
                  <div class="col-12 col-sm-6 h-75px" v-if="!isClient">
                    <v-select
                      v-model="pre_alert.customer_id"
                      label="Customer"
                      item-text="text"
                      item-value="index"
                      :items="serverData.clients"
                      clearable
                      outlined
                      dense
                      :disabled="isFullEditable()"
                      :error-messages="customer_idErrors"
                      @input="$v.pre_alert.customer_id.$touch()"
                      @blur="$v.pre_alert.customer_id.$touch()"
                    ></v-select>
                  </div>

                  <div
                    class="col-12 h-75px"
                    :class="[isClient ? 'col-sm-12' : 'col-sm-6']"
                  >
                    <v-autocomplete
                      v-model="pre_alert.warehouse_id"
                      label="Warehouse"
                      item-text="text"
                      item-value="index"
                      :items="serverData.warehouses"
                      clearable
                      outlined
                      dense
                      :disabled="isFullEditable()"
                      :error-messages="warehouse_idErrors"
                      @input="$v.pre_alert.warehouse_id.$touch()"
                      @blur="$v.pre_alert.warehouse_id.$touch()"
                    ></v-autocomplete>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.reference_number"
                      label="Reference number"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.carrier"
                      label="Carrier"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.supplier_reference_code"
                      label="Supplier reference code"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>

                  <div class="col-12 col-sm-6 h-75px">
                    <v-datetime-picker
                      label="Expected arrival time"
                      :textFieldProps="{
                        outlined: true,

                        dense: true,
                        'prepend-icon': 'mdi-calendar',
                        'error-messages': expected_arrival_timeErrors,
                      }"
                      :disabled="isFullEditable()"
                      v-model="pre_alert.expected_arrival_time"
                      @input="$v.pre_alert.expected_arrival_time.$touch()"
                      @blur="$v.pre_alert.expected_arrival_time.$touch()"
                    >
                    </v-datetime-picker>
                  </div>
                  <div class="col-12 col-sm-6 h-75px">
                    <v-datetime-picker
                      label="Actual Arrival Time"
                      :textFieldProps="{
                        outlined: true,
                        dense: true,
                        'prepend-icon': 'mdi-calendar',
                        'error-messages': actual_arrival_timeErrors,
                      }"
                      :datePickerProps="{}"
                      :timePickerProps="{}"
                      v-model="pre_alert.actual_arrival_time"
                      @input="$v.pre_alert.actual_arrival_time.$touch()"
                      @blur="$v.pre_alert.actual_arrival_time.$touch()"
                    >
                    </v-datetime-picker>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.po_number"
                      label="Purchase Order Number"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.vehicle_number"
                      label="Vehicle Number"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-autocomplete
                      v-model="pre_alert.vehicle_type_id"
                      label="Vehicle Type"
                      clearable
                      outlined
                      dense
                      item-text="text"
                      item-value="index"
                      :items="serverData.vehicle_types"
                    ></v-autocomplete>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.vehicle_temperature"
                      label="Vehicle Temperature"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.invoice_number"
                      label="Invoice Number"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 h-75px">
                    <v-text-field
                      v-model="pre_alert.number_of_boxes"
                      label="Number of Boxes"
                      clearable
                      outlined
                      dense
                      type="number"
                      @change="
                        () =>
                          validateMinValue('number_of_boxes', 0, 'pre_alert')
                      "
                    ></v-text-field>
                  </div>
                  <div class="col-12 h-75px">
                    <v-text-field
                      v-model="pre_alert.note"
                      label="Comment"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content step="1" key="ASN Information">
                <div class="row pt-4">
                  <div class="col-12 col-sm-6 h-75px">
                    <v-select
                      v-model="asn_info.asn_type_id"
                      label="ASN type"
                      item-text="text"
                      item-value="index"
                      :items="serverData.asn_types"
                      clearable
                      outlined
                      dense
                      :disabled="isFullEditable()"
                      :error-messages="asn_type_idErrors"
                      @input="$v.asn_info.asn_type_id.$touch()"
                      @blur="$v.asn_info.asn_type_id.$touch()"
                    ></v-select>
                  </div>
                  <div
                    v-if="!onlyPreAlert"
                    class="col-12 col-sm-6 h-75px d-flex align-start justify-space-between ma-0 pa-0 px-3"
                  >
                    <h4 class="pr-4 pt-5 font-weight-regular">Put on hold</h4>
                    <v-switch
                      v-model="asn_info.release_status"
                      hide-details
                      dense
                      :disabled="isFullEditable()"
                    />
                  </div>
                  <div
                    class="col-12 ma-0 pa-0 row"
                    v-if="asn_info.asn_type_id === 5"
                  >
                    <div class="col-12 col-sm-6 h-75px">
                      <v-text-field
                        v-model="asn_info.original_order_awb"
                        label="Original order awb"
                        clearable
                        outlined
                        dense
                        :error-messages="original_order_awbErrors"
                        @input="$v.asn_info.original_order_awb.$touch()"
                        @blur="$v.asn_info.original_order_awb.$touch()"
                      ></v-text-field>
                    </div>
                    <div class="col-12 col-sm-6 h-75px">
                      <v-text-field
                        v-model="asn_info.original_order_no"
                        label="Orginal order number"
                        clearable
                        outlined
                        dense
                        :error-messages="original_order_noErrors"
                        @input="$v.asn_info.original_order_no.$touch()"
                        @blur="$v.asn_info.original_order_no.$touch()"
                      ></v-text-field>
                    </div>
                    <div class="col-12 col-sm-6 h-75px">
                      <v-text-field
                        v-model="asn_info.return_order_awb"
                        label="Return order awb"
                        clearable
                        outlined
                        dense
                        :error-messages="return_order_awbErrors"
                        @input="$v.asn_info.return_order_awb.$touch()"
                        @blur="$v.asn_info.return_order_awb.$touch()"
                      ></v-text-field>
                    </div>
                    <div
                      class="col-12 col-sm-6 h-75px d-flex align-start justify-space-between ma-0 pa-0 px-3"
                    >
                      <h4 class="pr-4 pt-5 font-weight-regular">Replacement</h4>
                      <v-switch
                        v-model="asn_info.replacement_indicator"
                        :true-value="1"
                        :false-value="0"
                        hide-details
                        dense
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <v-radio-group
                      label="Receiving type"
                      v-model="asn_info.receiving_type"
                      row
                    >
                      <v-radio
                        v-for="(desti, i) in types"
                        :label="desti.text"
                        :value="desti.index"
                        :key="i"
                        :disabled="isFullEditable()"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="col-12">
                    <sku-datatable
                      :carriers="serverData.carriers"
                      :clientId="userId"
                      :customer="getCustomerData"
                      :uoms="serverData.uom_types"
                      :isEditable="isFullEditable()"
                      :receiving_type="asn_info.receiving_type"
                      :item="itemForAction"
                    ></sku-datatable>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <div class="d-flex align-center modal-action flex-wrap pt-4">
            <div class="justify-content-between align-center d-flex col-12">
              <v-btn
                elevation="0"
                class="order-1 order-sm-1"
                color="primary"
                dark
                :class="{ invisible: !backState }"
                @click="nextStep('prev')"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <div
                class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
                :class="{ invisible: !backState }"
              >
                <button
                  type="submit"
                  class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                  @click="resetEditForm"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                  @click="submitEditForm"
                >
                  Submit
                </button>
              </div>
              <v-btn
                elevation="0"
                class="order-3 order-sm-3"
                color="primary"
                dark
                :class="{ invisible: !nextState }"
                @click="nextStep('next')"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import SkuDatatable from "@/own/components/fulfillment/asn/skuEditContainer/SkuDatatable";
import ApiService from "@/core/services/api.service";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  validations() {
    return {
      pre_alert: {
        warehouse_id: { required },
        expected_arrival_time: {
          required: requiredIf(function () {
            return !this.isFullEditable();
          }),
        },
        actual_arrival_time: {
          required: requiredIf(function () {
            return this.canProcess;
          }),
        },
        customer_id: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
      },
      asn_info: {
        asn_type_id: { required },
        original_order_awb: {
          required: requiredIf(function () {
            return this.asn_info.asn_type_id === 5;
          }),
        },
        original_order_no: {
          required: requiredIf(function () {
            return this.asn_info.asn_type_id === 5;
          }),
        },
        return_order_awb: {
          required: requiredIf(function () {
            return this.asn_info.asn_type_id === 5;
          }),
        },
      },
    };
  },
  components: { SkuDatatable },
  props: ["refresher", "pageLoader", "itemForAction"],
  data: () => ({
    updateFlag: false,
    dialog: false,
    serverData: null,
    menu2: false,
    formData: {
      id: null,
      skus: [],
    },
    types: [
      { text: "By Scanning each", index: 1 },
      { text: "Bulk", index: 2 },
      { text: "Carton", index: 3 },
    ],
    pre_alert: {
      customer_id: null,
      warehouse_id: null,
      carrier: null,
      supplier_reference_code: null,
      expected_arrival_time: null,
      po_number: null, // Purchase Order Number
      vehicle_number: null,
      vehicle_type_id: null,
      vehicle_temperature: null,
      invoice_number: null,
      number_of_boxes: null,
      actual_arrival_time: null,
      note: null,
      reference_number: null,
    },
    asn_info: {
      asn_type_id: null,
      receiving_type: 1,
      release_status: true, // put on hold
      replacement_indicator: 0,
      original_order_awb: null,
      original_order_no: null,
      return_order_awb: null,
    },
    tab: 0,
    categories: [
      {
        name: "Pre-Alert Information",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`pre_alert`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "ASN Information",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`asn_info`].$invalid;
          } catch {
            return true;
          }
        },
      },
    ],
  }),
  methods: {
    isFullEditable() {
      return this.serverData.asn.partially_update;
    },
    nextStep(type) {
      this.updateValidationStateOfSteps();
      if (type === "next") {
        if (this.tab < 1) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    dateFormatter(date) {
      try {
        if (!Object.prototype.toString.call(date) === "[object Date]") {
          return date;
        }
        if (!date) {
          return null;
        }
        let d = date;
        return (
          [
            d.getFullYear(),
            d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
            d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
          ].join("-") +
          " " +
          [
            d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
            d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
            d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
          ].join(":")
        );
      } catch {
        return null;
      }
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    updateValidationStateOfSteps() {
      // console.log(`working`);
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
        // console.log(cat);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      ApiService.post("/fulfillment/asn/edit", data)
        .then((response) => {
          this.serverData = response.data;

          this.serverData.skus = response.data.skus.map((sku) => ({
            skuId: sku.index,
            systemCode: sku.text,
          }));

          // this.formData = { ...this.serverData.asn };
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
          this.dialog = false;
        });
    },
    submitEditForm() {
      this.updateValidationStateOfSteps();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // if (this.formData.skus.length === 0) {
      //   Swal.fire({
      //     title: "Warning",
      //     html: `<div>At least one sku is required!</div>`,
      //     icon: "warning",
      //     showConfirmButton: false,
      //     timer: 2500,
      //   });
      //   return;
      // }

      this.pageLoader(true);
      let data = JSON.parse(
        JSON.stringify({
          id: this.formData.id,
          ...this.pre_alert,
          ...this.asn_info,
        })
      );
      data.skus = [];
      if (data.expected_arrival_time && data.expected_arrival_time !== "") {
        data.expected_arrival_time = this.dateFormatter(
          this.pre_alert.expected_arrival_time
        );
      }

      if (data.actual_arrival_time && data.actual_arrival_time !== "") {
        data.actual_arrival_time = this.dateFormatter(
          this.pre_alert.actual_arrival_time
        );
      }
      ApiService.post("/fulfillment/asn/update", data)
        .then(() => {
          swalEdited();
          this.toggleModal();

          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    dateMaker(data) {
      return new Date(data);
    },
    async resetEditForm() {
      this.$v.$reset();
      const dataNames = ["formData", "pre_alert", "asn_info"];
      if (this.serverData) {
        dataNames.forEach(async (dataName) => {
          let copy = { ...this.serverData.asn };
          await Object.entries(this[`${dataName}`]).forEach((localD) => {
            Object.entries(this.serverData.asn).forEach((serverD) => {
              if (localD[0] === serverD[0] && localD[0] !== "image") {
                this[`${dataName}`][localD[0]] = copy[serverD[0]];
              }
            });
          });
        });
        if (
          this.pre_alert.expected_arrival_time &&
          this.pre_alert.expected_arrival_time !== ""
        ) {
          this.pre_alert.expected_arrival_time = this.dateMaker(
            this.serverData.asn.expected_arrival_time
          );
        }

        if (
          this.pre_alert.actual_arrival_time &&
          this.pre_alert.actual_arrival_time !== ""
        ) {
          this.pre_alert.actual_arrival_time = this.dateMaker(
            this.serverData.asn.actual_arrival_time
          );
        }
        if (this.onlyPreAlert) {
          this.asn_info.release_status = true;
        }
      } else {
        this.formData = {
          id: null,
          skus: [],
        };
        this.pre_alert = {
          customer_id: null,
          warehouse_id: null,
          carrier: null,
          supplier_reference_code: null,
          expected_arrival_time: null,
          actual_arrival_time: null,
          po_number: null, // Purchase Order Number
          vehicle_number: null,
          vehicle_type_id: null,
          vehicle_temperature: null,
          invoice_number: null,
          number_of_boxes: null,
          note: null,
          reference_number: null,
        };
        this.asn_info = {
          asn_type_id: null,
          receiving_type: 1,
          release_status: false, // put on hold
          replacement_indicator: 0,
          original_order_awb: null,
          original_order_no: null,
          return_order_awb: null,
        };
      }
      await this.resetValidationStateOfSteps();
      this.tab = 0;
    },

    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    canProcess() {
      return this.$store.getters.getASNTableStructure?.canProcess;
    },
    onlyPreAlert() {
      return this.$store.getters.getASNTableStructure?.onlyPreAlert;
    },
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 1;
    },
    isStepperReady() {
      try {
        return !!this.$v;
      } catch (error) {
        return false;
      }
    },
    userId: function () {
      return this.isClient ? false : this.pre_alert.customer_id;
    },
    getCustomerData() {
      return this.pre_alert.customer_id ? this.pre_alert.customer_id : false;
      // return this.$store.getters.currentUser.data.is_client
      //   ? this.$store.getters.currentUser.data.customer_id
      //   : false;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this, "pre_alert");
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },

    asn_type_idErrors() {
      return this.handleFormValidation("asn_type_id", this, "asn_info");
    },
    original_order_awbErrors() {
      return this.handleFormValidation("original_order_awb", this, "asn_info");
    },
    original_order_noErrors() {
      return this.handleFormValidation("original_order_no", this, "asn_info");
    },
    return_order_awbErrors() {
      return this.handleFormValidation("return_order_awb", this, "asn_info");
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this, "pre_alert");
    },
    expected_arrival_timeErrors() {
      return this.handleFormValidation(
        "expected_arrival_time",
        this,
        "pre_alert"
      );
    },
    actual_arrival_timeErrors() {
      return this.handleFormValidation(
        "actual_arrival_time",
        this,
        "pre_alert"
      );
    },
  },
  watch: {
    "asn_info.receiving_type": {
      handler(newValue, oldValue) {
        if (this.formData.skus.length == 0) {
          return;
        }
        if (this.updateFlag) {
          this.updateFlag = false;
          return;
        }
        Swal.fire({
          title: "Are you sure?",
          text: `After changing reviving type you need to recheck item details!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "NO",
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          } else {
            this.updateFlag = true;
            this.asn_info.receiving_type = oldValue;
          }
        });
      },
      deep: true,
    },
  },
};
</script>
