<template>
  <date-range-picker
    class="w-100"
    ref="picker"
    :opens="position"
    :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
    :close-on-esc="false"
    :showDropdowns="true"
    :ranges="false"
    single-date-picker
    min-date="01-01-2018"
    :max-date="new Date().toLocaleDateString()"
    control-container-class="form-control max-h-45px"
    v-model="dateRange"
    @update="updateDateValue"
  >
    <template v-slot:input="picker">
      <input
        type="text"
        class="custom-input"
        placeholder="Select a date"
        :value="value ? value : null"
      />
    </template>

    <template v-slot:footer="picker">
      <div class="drp-buttons py-4 px-6 poppins">
        <button
          type="button"
          class="btn btn-light ls1 py-2 px-7"
          @click="
            () => {
              picker.clickCancel();
              clearDate();
            }
          "
        >
          Cancel</button
        ><button
          @click="picker.clickApply"
          type="button"
          class="btn btn--print-upload ls1 py-2 px-4"
        >
          Apply
        </button>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "DateTimePicker",
  components: { DateRangePicker },
  props: {
    position: { type: String, default: "center" },
    value: { default: null },
    clearDate: { default: Function },
  },
  data() {
    return {
      dateRange: {
        startDate: this.today(),
        endDate: this.today(),
      },
    };
  },
  methods: {
    dataOfFilters() {
      let startDate, endDate;
      const pattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;

      if (pattern.test(this.dateRange.startDate)) {
        startDate = this.slashSeperatedToISO(this.dateRange.startDate);
        endDate = this.slashSeperatedToISO(this.dateRange.endDate);
      } else {
        startDate = this.extendedToISO(this.dateRange.startDate);
        endDate = this.extendedToISO(this.dateRange.endDate);
      }
      const filterData = {
        date_range_type: "custom",
        date_range: [startDate, endDate],
        ...this.filterData,
      };
      return filterData;
    },
    slashSeperatedToISO(date) {
      const [month, day, year] = date.split("/");
      const js_date = new Date(year, month - 1, day);
      return this.dateFormatter(js_date);
    },
    extendedToISO(val) {
      const date = new Date(val);
      return this.dateFormatter(date);
    },
    today() {
      const d = new Date();
      return this.dateFormatter(d);
    },
    updateDateValue() {
      let startDate, endDate;
      const pattern = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/;

      if (pattern.test(this.dateRange.startDate)) {
        startDate = this.slashSeperatedToISO(this.dateRange.startDate);
        endDate = this.slashSeperatedToISO(this.dateRange.endDate);
      } else {
        startDate = this.extendedToISO(this.dateRange.startDate);
        endDate = this.extendedToISO(this.dateRange.endDate);
      }

      this.$emit("on-submit", [startDate, endDate]);
    },
    dateFormatter(date, charForJoin = "-") {
      return [
        date.getFullYear(),
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`,
        date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
      ].join(charForJoin);
    },
  },
  computed: {},
  mounted() {
    this.updateDateValue();
  },
};
</script>

<style lang="scss">
.table-condensed {
  // .off {
  //   display: none;
  // }
  .off.in-range {
    background-color: #ebf4f8 !important;
  }
  .end-date {
    background-color: rgba(183, 4, 126, 0.8) !important;
  }
}
.weekend.active.in-range.end-date {
  background-color: rgba(183, 4, 126, 0.8) !important;
}
.date-range-selector {
  margin-top: 10px !important;
  .v-input__control {
    .v-input__slot {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.vue-daterange-picker {
  .form-control {
    border: none;
  }
}
.custom-input:focus-visible {
  outline: none !important;
}
</style>
